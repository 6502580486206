/* eslint-disable import/no-anonymous-default-export */
  export const firebaseConfig = {
    apiKey: "AIzaSyA8DS7GUp0TDfmnkJeeFBZ4Fe1K0fmYx5s",
    authDomain: "ncmch-sjt-dev-6f64c.firebaseapp.com",
    databaseURL: "https://ncmch-sjt-dev-6f64c-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "ncmch-sjt-dev-6f64c",
    storageBucket: "ncmch-sjt-dev-6f64c.appspot.com",
    messagingSenderId: "608049621074",
    appId: "1:608049621074:web:a4833d84db3215b7dd578b",
    measurementId: "G-P4HLZCMW34",
  };